import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import NotificationAlert from 'react-notification-alert'
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Badge,
  Modal,
} from 'reactstrap'
import { PoolContext } from '../../context/pool-context'

const Operations = () => {
  //Context Variables
  const { opsUnassigned } = useContext(PoolContext)

  //Own State
  const [ops, setOps] = useState([])
  const [notificationModal, setnotificationModal] = useState(false)
  const [opId, setOpId] = useState(0)

  const loadOps = async () => {
    const url = process.env.REACT_APP_SERVER_URL + '/api/agents/ops'
    await axios
      .get(url)
      .then((response) => {
        setOps(response.data)
        console.log('operations loaded')
      })
      .catch((error) => {
        console.log('there was an error')
      })
  }

  useEffect(() => {
    loadOps()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showModal = (show, id) => {
    setOpId(id)
    setnotificationModal(show)
  }

  const paidOperation = async (id, status) => {
    const url = process.env.REACT_APP_SERVER_URL + '/api/agents/ops'
    axios
      .post(url, {
        id: id,
        status: status,
      })
      .then((response) => {
        loadOps()
        notify('default', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const notificationAlertRef = React.useRef(null)

  const notify = (type, data) => {
    let options = {
      place: 'tc',
      message: (
        <div className='alert-text'>
          <span className='alert-title' data-notify='title'>
            {' '}
            Success
          </span>
          <span data-notify='message'>{data}</span>
        </div>
      ),
      type: type,
      icon: 'ni ni-bell-55',
      autoDismiss: 7,
    }
    notificationAlertRef.current.notificationAlert(options)
  }

  return (
    <Container className='mt--1'>
      <div className='rna-wrapper'>
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Card>
        <CardHeader className='border-0'>
          <Row>
            <Col xs='6'>
              <h3 className='mb-0'>Operaciones Activas ({ops.length})</h3>
            </Col>
            <Col className='text-right' xs='6'>
              <Button
                className='btn'
                color='primary'
                onClick={(e) => e.preventDefault()}
                type='button'
              >
                <span>Disponibles</span>
                <Badge
                  className='badge-circle badge-floating border-white'
                  color='danger'
                  size='md'
                >
                  {opsUnassigned.length}
                </Badge>
              </Button>
            </Col>
          </Row>
        </CardHeader>

        <Table className='align-items-center table-flush' responsive>
          <thead className='thead-light'>
            <tr>
              <th>Tipo</th>
              <th>Beneficiario</th>
              <th />
              <th>Monto</th>
              <th />
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {ops.map((operation) => {
              return (
                <tr key={operation.id}>
                  <td className='table-user'>
                    <b>{operation.tipo}</b>
                  </td>
                  <td>
                    <span className='text-muted'>{operation.beneficiario}</span>
                  </td>
                  <td>
                    <a
                      className='table-action'
                      href='#pablo'
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className='ni ni-ungroup' />
                    </a>
                  </td>
                  <td>
                    <span className='text-muted'>{operation.monto}</span>
                  </td>
                  <td>
                    <a className='table-action mx-3' href='#pablo'>
                      <i className='ni ni-ungroup' />
                    </a>
                  </td>
                  <td className='table-actions'>
                    <Button
                      className=' btn-round btn-icon'
                      color='success'
                      href='#pablo'
                      id='tooltip969372949'
                      onClick={() => showModal(true, operation.id)}
                      //onClick={() => paidOperation(operation.id, 'paid')}
                      size='sm'
                    >
                      <span className='btn-inner--text'>Pagado</span>
                    </Button>
                    <Modal
                      className='modal-dialog-centered modal-danger'
                      contentClassName='bg-gradient-default'
                      isOpen={notificationModal}
                      toggle={() => setnotificationModal(false)}
                    >
                      <div className='modal-header'>
                        <h6
                          className='modal-title'
                          id='modal-title-notification'
                        >
                          Operación {opId}
                        </h6>
                        <button
                          aria-label='Close'
                          className='close'
                          data-dismiss='modal'
                          type='button'
                          onClick={() => setnotificationModal(false)}
                        >
                          <span aria-hidden={true}>×</span>
                        </button>
                      </div>
                      <div className='modal-body'>
                        <div className='py-3 text-center'>
                          <h4 className='heading mt-4'>
                            Encabezado de la operación
                          </h4>
                          <p>Mostrar datos de la operación a pagar</p>
                        </div>
                      </div>
                      <div className='modal-footer'>
                        <Button
                          className='btn-white'
                          color='default'
                          type='button'
                          onClick={() => setnotificationModal(false)}
                        >
                          Confirmar
                        </Button>
                        <Button
                          className='text-white ml-auto'
                          color='link'
                          data-dismiss='modal'
                          type='button'
                          onClick={() => setnotificationModal(false)}
                        >
                          Cancelar
                        </Button>
                      </div>
                    </Modal>
                    <Button
                      className=' btn-round btn-icon'
                      color='danger'
                      href='#pablo'
                      id='tooltip969372949'
                      onClick={(e) => e.preventDefault()}
                      size='sm'
                    >
                      <span className='btn-inner--text'>Error</span>
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Card>
    </Container>
  )
}

export default Operations
