import React, { useContext } from 'react'
import { AgentContext } from '../context/agent-context'
import ManagerBar from './sections/manager-bar'

export const AgentBar = () => {
  const { roles } = useContext(AgentContext)
  return roles.includes('Agent') ? (
    <ManagerBar name='Agent' parent='Agent' link='/' />
  ) : (
    <></>
  )
}

export const AdminBar = () => {
  const { roles } = useContext(AgentContext)
  return roles.includes('Admin') ? (
    <ManagerBar name='Admin' parent='Admin' link='admin' />
  ) : (
    <></>
  )
}

export const ManageBar = () => {
  const { roles } = useContext(AgentContext)
  return roles.includes('Manager') ? (
    <ManagerBar name='Manager' parent='Manager' link='manage' />
  ) : (
    <></>
  )
}
