import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { HomePage } from '../../views'
import { Login } from './'

const AuthRedirect = () => {
  const { isAuthenticated } = useAuth0()

  return isAuthenticated ? <HomePage /> : <Login />
}

export default AuthRedirect
