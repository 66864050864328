import { withAuthenticationRequired } from '@auth0/auth0-react'
import React from 'react'
import AdminContent from '../components/admin-content'
import { Loading } from '../components/auth'
import MainTheme from '../themes/main'

const AdminPage = () => <MainTheme content={<AdminContent />} />

export default withAuthenticationRequired(AdminPage, {
  onRedirecting: () => <Loading />,
})
