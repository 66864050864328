import React, { useContext, useState } from 'react'
import { AgentContext } from '../context/agent-context'
import { AdminBar, ManageBar } from './manage-bars'

import Operations from './sections/operations'

const HomeContent = () => {
  return (
    <>
      <AdminBar />
      <ManageBar />
      <Operations />
    </>
  )
}

export default HomeContent
