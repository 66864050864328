import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect, createContext, useState } from 'react'
import axios from 'axios'

export const PoolContext = createContext()

const PoolContextProvider = (props) => {
  const { getAccessTokenSilently } = useAuth0()

  const [opsUnassigned, setOperationsUnassigned] = useState([])

  const loadUnassigned = async () => {
    const url = process.env.REACT_APP_SERVER_URL + '/api/agents/ops-un'
    await axios
      .get(url)
      .then((response) => {
        setOperationsUnassigned(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    loadUnassigned()
  }, [])

  return (
    <PoolContext.Provider
      value={{
        opsUnassigned,
      }}
    >
      {props.children}
    </PoolContext.Provider>
  )
}

export default PoolContextProvider
