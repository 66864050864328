import React from 'react'
import { Footer, MainNav } from '../components'
import AgentContextProvider from '../context/agent-context'
import PoolContextProvider from '../context/pool-context'

const MainTheme = ({ content }) => {
  return (
    <AgentContextProvider>
      <PoolContextProvider>
        <MainNav />
        <div className='main-content'>
          <section className='pb-9 bg-default'>
            {content}
            <Footer />
          </section>
        </div>
      </PoolContextProvider>
    </AgentContextProvider>
  )
}

export default MainTheme
