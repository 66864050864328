import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { DropdownItem } from 'reactstrap'

const Logout = () => {
  const { logout } = useAuth0()

  const logOut = () => {
    logout({
      returnTo: window.location.origin,
    })
  }

  return (
    <DropdownItem className='danger' href='#pablo' onClick={() => logOut()}>
      <i className='ni ni-user-run' />
      <span>Logout</span>
    </DropdownItem>
  )
}

export default Logout
