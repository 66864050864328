import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Loading, AuthRedirect } from './components/auth'
import { ManagePage, AdminPage } from './views'

const App = () => {
  const { isLoading } = useAuth0()

  if (isLoading) {
    return <Loading />
  }

  return (
    <Switch>
      <Route path='/' exact component={AuthRedirect} />
      <Route path='/manage' component={ManagePage} />
      <Route path='/admin' component={AdminPage} />
      <Route path='*' component={AuthRedirect} />
    </Switch>
  )
}

export default App
