import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col,
} from 'reactstrap'

const Login = () => {
  const { loginWithRedirect } = useAuth0()
  return (
    <Container className='fluid h-100'>
      <Row className='login-row'>
        <Col lg='5' md='7'>
          <Card className='bg-secondary border-0 mb-0'>
            <CardHeader className='bg-transparent pb-2'>
              <div className='text-muted text-center mt-2 mb-3'>
                <h3>Sign in to access dashboard</h3>
              </div>
            </CardHeader>
            <CardBody>
              <div className='text-center text-muted mb-1'>
                <small>Click Login button to redirect to Login Page</small>
              </div>
              <Form role='form'>
                <div className='text-center'>
                  <Button
                    className='my-4'
                    color='info'
                    type='button'
                    onClick={() => loginWithRedirect()}
                  >
                    Login
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default Login
