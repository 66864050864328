import React, { useContext } from 'react'
import { Logout } from './auth'
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  ListGroupItem,
  ListGroup,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { AgentContext } from '../context/agent-context'

const MainNav = () => {
  const { agent } = useContext(AgentContext)
  const { picture, name } = agent

  return (
    <>
      <Navbar className='navbar-top navbar-expand border-bottom bg-info navbar-dark'>
        <Container fluid>
          <Nav className='align-items-center' navbar>
            <Row className='justify-content-start'>
              <Col className='d-none d-md-flex'>
                <NavItem>
                  <NavLink to='/auth/pricing' tag={Link}>
                    <span className='nav-link-inner--text'>Pricing</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to='/auth/login' tag={Link}>
                    <span className='nav-link-inner--text'>Login</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to='/auth/register' tag={Link}>
                    <span className='nav-link-inner--text'>Register</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to='/auth/lock' tag={Link}>
                    <span className='nav-link-inner--text'>Lock</span>
                  </NavLink>
                </NavItem>
              </Col>
              <Col className='d-block flex-column d-md-none'>
                <UncontrolledDropdown nav>
                  <DropdownToggle className='nav-link' color='' tag='a'>
                    <i className='ni ni-ungroup' />
                  </DropdownToggle>
                  <DropdownMenu
                    className='dropdown-menu-lg dropdown-menu-dark bg-gray  '
                    right
                  >
                    <Row
                      className='shortcuts px-4'
                      style={{ minWidth: '120px' }}
                    >
                      <Col
                        className='shortcut-item'
                        href='#pablo'
                        onClick={(e) => e.preventDefault()}
                        xs='5'
                        tag='a'
                      >
                        <span className='shortcut-media avatar rounded-circle bg-gradient-info'>
                          <i className='ni ni-credit-card' />
                        </span>
                        <small>Payments</small>
                      </Col>
                      <Col
                        className='shortcut-item'
                        href='#pablo'
                        onClick={(e) => e.preventDefault()}
                        xs='5'
                        tag='a'
                      >
                        <span className='shortcut-media avatar rounded-circle bg-gradient-green'>
                          <i className='ni ni-books' />
                        </span>
                        <small>Reports</small>
                      </Col>
                      <Col
                        className='shortcut-item'
                        href='#pablo'
                        onClick={(e) => e.preventDefault()}
                        xs='5'
                        tag='a'
                      >
                        <span className='shortcut-media avatar rounded-circle bg-gradient-purple'>
                          <i className='ni ni-pin-3' />
                        </span>
                        <small>Maps</small>
                      </Col>
                      <Col
                        className='shortcut-item'
                        href='#pablo'
                        onClick={(e) => e.preventDefault()}
                        xs='5'
                        tag='a'
                      >
                        <span className='shortcut-media avatar rounded-circle bg-gradient-yellow'>
                          <i className='ni ni-basket' />
                        </span>
                        <small>Shop</small>
                      </Col>
                    </Row>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
            </Row>
          </Nav>
          <Nav
            className='align-items-center justify-content-end mr-md-1'
            navbar
          >
            <UncontrolledDropdown nav>
              <DropdownToggle className='nav-link' color='' tag='a'>
                <i className='ni ni-bell-55' />
              </DropdownToggle>
              <DropdownMenu
                className='dropdown-menu-xl py-0 overflow-hidden'
                right
              >
                <div className='px-3 py-3'>
                  <h6 className='text-sm text-muted m-0'>
                    You have <strong className='text-info'>13</strong>{' '}
                    notifications.
                  </h6>
                </div>

                <ListGroup flush>
                  <ListGroupItem
                    className='list-group-item-action'
                    href='#pablo'
                    onClick={(e) => e.preventDefault()}
                    tag='a'
                  >
                    <Row className='align-items-center'>
                      <Col className='col-auto'>
                        <img
                          alt='...'
                          className='avatar rounded-circle'
                          src={
                            require('../assets/img/theme/team-1.jpg').default
                          }
                        />
                      </Col>
                      <div className='col ml--2'>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div>
                            <h4 className='mb-0 text-sm'>John Snow</h4>
                          </div>
                          <div className='text-right text-muted'>
                            <small>2 hrs ago</small>
                          </div>
                        </div>
                        <p className='text-sm mb-0'>
                          Let's meet at Starbucks at 11:30. Wdyt?
                        </p>
                      </div>
                    </Row>
                  </ListGroupItem>
                  <ListGroupItem
                    className='list-group-item-action'
                    href='#pablo'
                    onClick={(e) => e.preventDefault()}
                    tag='a'
                  >
                    <Row className='align-items-center'>
                      <Col className='col-auto'>
                        <img
                          alt='...'
                          className='avatar rounded-circle'
                          src={
                            require('../assets/img/theme/team-2.jpg').default
                          }
                        />
                      </Col>
                      <div className='col ml--2'>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div>
                            <h4 className='mb-0 text-sm'>John Snow</h4>
                          </div>
                          <div className='text-right text-muted'>
                            <small>3 hrs ago</small>
                          </div>
                        </div>
                        <p className='text-sm mb-0'>
                          A new issue has been reported for Argon.
                        </p>
                      </div>
                    </Row>
                  </ListGroupItem>
                  <ListGroupItem
                    className='list-group-item-action'
                    href='#pablo'
                    onClick={(e) => e.preventDefault()}
                    tag='a'
                  >
                    <Row className='align-items-center'>
                      <Col className='col-auto'>
                        <img
                          alt='...'
                          className='avatar rounded-circle'
                          src={
                            require('../assets/img/theme/team-3.jpg').default
                          }
                        />
                      </Col>
                      <div className='col ml--2'>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div>
                            <h4 className='mb-0 text-sm'>John Snow</h4>
                          </div>
                          <div className='text-right text-muted'>
                            <small>5 hrs ago</small>
                          </div>
                        </div>
                        <p className='text-sm mb-0'>
                          Your posts have been liked a lot.
                        </p>
                      </div>
                    </Row>
                  </ListGroupItem>
                  <ListGroupItem
                    className='list-group-item-action'
                    href='#pablo'
                    onClick={(e) => e.preventDefault()}
                    tag='a'
                  >
                    <Row className='align-items-center'>
                      <Col className='col-auto'>
                        <img
                          alt='...'
                          className='avatar rounded-circle'
                          src={
                            require('../assets/img/theme/team-4.jpg').default
                          }
                        />
                      </Col>
                      <div className='col ml--2'>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div>
                            <h4 className='mb-0 text-sm'>John Snow</h4>
                          </div>
                          <div className='text-right text-muted'>
                            <small>2 hrs ago</small>
                          </div>
                        </div>
                        <p className='text-sm mb-0'>
                          Let's meet at Starbucks at 11:30. Wdyt?
                        </p>
                      </div>
                    </Row>
                  </ListGroupItem>
                  <ListGroupItem
                    className='list-group-item-action'
                    href='#pablo'
                    onClick={(e) => e.preventDefault()}
                    tag='a'
                  >
                    <Row className='align-items-center'>
                      <Col className='col-auto'>
                        <img
                          alt='...'
                          className='avatar rounded-circle'
                          src={
                            require('../assets/img/theme/team-5.jpg').default
                          }
                        />
                      </Col>
                      <div className='col ml--2'>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div>
                            <h4 className='mb-0 text-sm'>John Snow</h4>
                          </div>
                          <div className='text-right text-muted'>
                            <small>3 hrs ago</small>
                          </div>
                        </div>
                        <p className='text-sm mb-0'>
                          A new issue has been reported for Argon.
                        </p>
                      </div>
                    </Row>
                  </ListGroupItem>
                </ListGroup>

                <DropdownItem
                  className='text-center text-info font-weight-bold py-3'
                  href='#pablo'
                  onClick={(e) => e.preventDefault()}
                >
                  View all
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown nav>
              <DropdownToggle className='nav-link pr-0' color='' tag='a'>
                <Media className='align-items-center'>
                  <span className='avatar avatar-sm rounded-circle'>
                    <img
                      alt='...'
                      src={
                        //require('../assets/img/theme/team-4.jpg').default
                        picture
                      }
                    />
                  </span>
                  <Media className='ml-2 d-none d-lg-block'>
                    <span className='mb-0 text-sm font-weight-bold'>
                      {name}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem className='noti-title' header tag='div'>
                  <h6 className='text-overflow m-0'>Welcome!</h6>
                </DropdownItem>
                <DropdownItem href='#pablo' onClick={(e) => e.preventDefault()}>
                  <i className='ni ni-single-02' />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem href='#pablo' onClick={(e) => e.preventDefault()}>
                  <i className='ni ni-settings-gear-65' />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem href='#pablo' onClick={(e) => e.preventDefault()}>
                  <i className='ni ni-calendar-grid-58' />
                  <span>Activity</span>
                </DropdownItem>
                <DropdownItem href='#pablo' onClick={(e) => e.preventDefault()}>
                  <i className='ni ni-support-16' />
                  <span>Support</span>
                </DropdownItem>
                <DropdownItem divider />
                <Logout />
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  )
}

export default MainNav
