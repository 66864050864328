import { withAuthenticationRequired } from '@auth0/auth0-react'
import React from 'react'
import { Loading } from '../components/auth'
import ManageContent from '../components/manage-content'
import MainTheme from '../themes/main'

const ManagePage = () => <MainTheme content={<ManageContent />} />

export default withAuthenticationRequired(ManagePage, {
  onRedirecting: () => <Loading />,
})
