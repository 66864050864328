import React from 'react'
import imgLoading from '../../assets/loading1.svg'

const Loading = () => (
  <>
    <div className='spinner'>
      <img src={imgLoading} alt='Loading...' />
    </div>
  </>
)

export default Loading
