import React from 'react'

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  Row,
  Col,
} from 'reactstrap'

const ManagerBar = ({ name, parent, link }) => {
  return (
    <>
      <div className='header header-dark bg-info pb-2 content__title content__title--calendar'>
        <Container fluid>
          <div className='header-body'>
            <Row className='align-items-center py-1'>
              <Col lg='6' xs='7'>
                <h6 className='fullcalendar-title h2 text-white d-inline-block mb-0'>
                  {name} Module
                </h6>{' '}
              </Col>
              <Col className='mt-3 mt-md-0 text-md-right' lg='6' xs='5'>
                <Button className='btn-neutral' color='default' size='sm'>
                  <a href={link}>Gestionar</a>
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default ManagerBar
