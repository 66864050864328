import React, { createContext, useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'

export const AgentContext = createContext()

const AgentContextProvider = (props) => {
  const { user } = useAuth0()
  const { name, picture, email, email_verified, sub } = user

  const { getAccessTokenSilently } = useAuth0()

  const [agent] = useState({
    name: name,
    picture: picture,
    email: email,
    verified: email_verified,
    id: sub,
  })

  const [roles, setRoles] = useState([])
  const [pool, setPool] = useState('')

  useEffect(() => {
    const loadRoles = async () => {
      const url = process.env.REACT_APP_SERVER_URL + '/api/agents'
      const roles = await axios.get(url)

      setRoles(roles.data)
    }

    const loadPool = async () => {
      const token = await getAccessTokenSilently()
      const url = process.env.REACT_APP_SERVER_URL + '/api/pools'
      await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setPool(response.data)
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    }

    loadRoles()
    loadPool()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AgentContext.Provider
      value={{
        agent,
        roles,
      }}
    >
      {props.children}
    </AgentContext.Provider>
  )
}

export default AgentContextProvider
