import React from 'react'
import { AgentBar, ManageBar } from './manage-bars'

const AdminContent = () => {
  return (
    <>
      <AgentBar />
      <ManageBar />
      <div>
        <h1>Administration Content</h1>
      </div>
    </>
  )
}

export default AdminContent
